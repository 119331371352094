.page-layout {
    margin: 0;
}

.ant-layout-sider {
    margin-top: 24px;
}

.ant-layout-sider-children > .ant-menu {
    height: 100%;
    background-color: @body-background;
}

.ant-select-selector {
    max-height: 100px;
    overflow: auto;
}

.ant-select-clear {
    top: 16px !important;
}
.ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important

}

.ant-tabs-content-holder {
    border: 1px solid #f0f0f0;
    border-top-style: none;
    padding: 0 0 0 10px;
}

span.anticon.menu-item-icon {
    font-size: 24px;
    position: absolute;
    top: 8px;
}
@body-background: rgb(240, 242, 245);@primary-color: #d8c072;@link-color: #a8a8a8;@btn-default-bg: white;@btn-default-color: #d8c072;@btn-default-border: #d8c072;@border-radius-base: 4px;