body {
    margin: 0;
}

#root, .ant-layout, .ant-layout-header > * {
    height: 100%;
}

.ant-layout-header {
    padding: 0 20px;
}

.header {
    background-color: rgb(51, 51, 51);
    width: 100%;
}

.header .logo {
    height: 35px;
}

.ant-card {
    min-height: 220px;
}

.ant-card-body {
    position: relative;
}
.ant-form-item-control {
    line-height: unset;
    min-height: 32px;
}

.ant-select-selection--multiple {
    max-height: 115px;
    overflow: auto;
}

.ant-timeline {
    margin-top: 3px;
}

.ant-timeline-item {
    padding: 0 0 10px;
}

.ant-timeline-item-last > .ant-timeline-item-content {
    min-height: unset;
}

.ant-select-selection--multiple .ant-select-selection__clear, .ant-select-selection--single .ant-select-selection__clear {
    top: -1px;
    right: -5px;
    font-size: 14px;
}
.ant-timeline-item-pending .ant-timeline-item-head {
    font-size: 12px;
}

.ant-select-clear .anticon-close-circle,
.ant-picker-clear .anticon-close-circle {
    top: 0px;
    right: -6px;
    font-size: 14px;
    color: #e47070;
}

.ant-select-clear .anticon-close-circle:hover {
    color: #e14141;
}

.ant-card-extra {
    color: #cccccc;
    font-size: 16px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 19px 16px 19px 40px;
}

.ant-card-head {
    background-color: #fafafa;
}

.ant-card-bordered {
    border: 1px solid #d9d9d9;
}

.margin-card-button {
    margin-bottom: 13px;
}

.card-button span {
    min-width: 62px;
}

.hide-empty-list .ant-list-empty-text {
    display: none;
}

.selectAll i {
    display: none;
}
